
<section class='navigation'>
    <a [routerLink]="['/']"><img src="../../assets/img/header.jpg"/></a>

    <div class='icons'>
        <a [routerLink]="['/']"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
        <a [routerLink]="['/projects']"  routerLinkActive="active">Projects</a>
        <a [routerLink]="['/about-me']" routerLinkActive="active" >About Me</a>
        <a [routerLink]="['/contact']" routerLinkActive="active" >Contact</a>
    </div>  
</section>
