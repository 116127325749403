<section class="intro_wrap">
<div class="skill-matcher-wrap">
    <div class="intro">
        <h3>Skill Matcher</h3>
        <p>You are looking for a Junior Web Developer but don't know if my skills and your tasks fit together?</p>
        <p>Enter your Tech Stack into my Skill Matcher and see at what percentage our Business Match is.</p>
    </div>
    <div class="matcher border-blue">
        <form>
            <input class="skill-input" placeholder="Please add skill here." #skillInput="ngModel" name="skillInput" type='text'required [(ngModel)]='response'>
            <button class="skill-button" (click)="addToArray()">add</button>
            <div class="skill-error" *ngIf="skillInput.touched && !skillInput.valid"></div>
        </form>
    </div>
    <div class="skill-wrap" >
    <span [ngClass]="skill.match ? 'styled-skill': 'no-skill'" *ngFor="let skill of skillsToHave">
        {{skill.skill}}
    </span>
    </div>
    <div *ngIf="skillInput.touched && !skillInput.valid" class="match-section">
       
        <button *ngIf="this.matchies" class="skill-button border-blue margin-bo" (click)="matching()">Show Business Match</button>
        
        <div *ngIf="matchesInProcent" class="counter">{{matchesInProcent}}%</div>
        <div *ngIf="matchesInProcent == 0" >Sorry, no match!</div>
        <div>
            <button class="skill-button border-blue margin-bo" [routerLink]="['/about-me/skills']" >See all skills</button>
            <button class="skill-button border-blue margin-bo"  [routerLink]="['/contact']" >Get in touch</button>
        </div>
    </div>
</div>

</section>