
<section class="category_wrap">
<div >
    <img class="image_wrap" src="../../assets/img/portait.jpg">
</div>

<h1 class='name'>Nacira Bogenschneider</h1>
<h2 class='job_title'>Web Developer & Graphic Designer</h2>

<div class='button_wrap'>
    <a [routerLink]="['skills']" >
        <button class="categorie">
            <svg routerLinkActive="active" class="bi bi-star-fill" width="30px" height="30px" viewBox="0 0 16 16" fill="#dbdbdb" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
            </svg>
        </button>
    </a> 
    <a [routerLink]="['experience']">
        <button class="categorie">
            <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 512 512' >
                <title>ionicons-v5-e</title>
                <path d='M336,80H176a16,16,0,0,0-16,16v16H352V96A16,16,0,0,0,336,80Z' style='fill:none'/>
                <path d='M496,176a64.07,64.07,0,0,0-64-64H384V96a48.05,48.05,0,0,0-48-48H176a48.05,48.05,0,0,0-48,48v16H80a64.07,64.07,0,0,0-64,64v48H496ZM352,112H160V96a16,16,0,0,1,16-16H336a16,16,0,0,1,16,16Z' fill="#dbdbdb" routerLinkActive="active"/>
                <path d='M336,264a24,24,0,0,1-24,24H200a24,24,0,0,1-24-24v-4a4,4,0,0,0-4-4H16V400a64,64,0,0,0,64,64H432a64,64,0,0,0,64-64V256H340a4,4,0,0,0-4,4Z' fill="#dbdbdb" routerLinkActive="active"/>
            </svg>
        </button>
    </a> 
    <a [routerLink]="['education']">
        <button class="categorie" >
            <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 512 512'>
                <title>ionicons-v5-q</title>
                <path d='M256,368a16,16,0,0,1-7.94-2.11L108,285.84a8,8,0,0,0-12,6.94V368a16,16,0,0,0,8.23,14l144,80a16,16,0,0,0,15.54,0l144-80A16,16,0,0,0,416,368V292.78a8,8,0,0,0-12-6.94L263.94,365.89A16,16,0,0,1,256,368Z' fill="#dbdbdb" routerLinkActive="active"/><path d='M495.92,190.5s0-.08,0-.11a16,16,0,0,0-8-12.28l-224-128a16,16,0,0,0-15.88,0l-224,128a16,16,0,0,0,0,27.78l224,128a16,16,0,0,0,15.88,0L461,221.28a2,2,0,0,1,3,1.74V367.55c0,8.61,6.62,16,15.23,16.43A16,16,0,0,0,496,368V192A14.76,14.76,0,0,0,495.92,190.5Z' fill="#dbdbdb" routerLinkActive="active"/>
            </svg>
        </button>
    </a> 
</div>
<router-outlet></router-outlet>
</section>



<section class="card">
    <div class="git">
        <div>
        <p>Profile on GitHub </p>
        <input type='text' [(ngModel)]='userName'>
        <button (click)="search()">search</button>
        </div>
        <div *ngIf="response">
            <p><span>Name: </span>{{response.name}}</p>
            <p><span>Location: </span>{{response.location}}</p>
            <p><span>URL: </span><a href='{{response.html_url}}'>{{response.html_url}}</a></p>
            <p><span>Login: </span>{{response.login}}</p>
            <p><span>Created: </span>{{response.created_at}}</p>
            <p><span>Followers: </span>{{response.followers}}</p>
            <p><span>Public Repos: </span>{{response.public_repos}}</p>
        </div>
    </div>
</section>