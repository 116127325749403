<div class="education_wrap">
    <h3>Education</h3>
    <div class='education_style' *ngFor="let educate of educationArray">
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 512 512'>
            <title>ionicons-v5-q</title>
            <path d='M256,368a16,16,0,0,1-7.94-2.11L108,285.84a8,8,0,0,0-12,6.94V368a16,16,0,0,0,8.23,14l144,80a16,16,0,0,0,15.54,0l144-80A16,16,0,0,0,416,368V292.78a8,8,0,0,0-12-6.94L263.94,365.89A16,16,0,0,1,256,368Z' fill="black" routerLinkActive="active"/><path d='M495.92,190.5s0-.08,0-.11a16,16,0,0,0-8-12.28l-224-128a16,16,0,0,0-15.88,0l-224,128a16,16,0,0,0,0,27.78l224,128a16,16,0,0,0,15.88,0L461,221.28a2,2,0,0,1,3,1.74V367.55c0,8.61,6.62,16,15.23,16.43A16,16,0,0,0,496,368V192A14.76,14.76,0,0,0,495.92,190.5Z' fill="black" routerLinkActive="active"/>
        </svg>
        <div class="journey_point">
            <p>{{educate.when}}</p>
            <p>{{educate.what}}</p>
            <p>{{educate.where}}</p>
        </div>
    </div>
</div>

