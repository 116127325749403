<section class="imprint_wrap">
    <div class="imprint-main">
    <h3>Imprint</h3> 
    <h5>According to § 5 TMG</h5>
    <p>Operator and Contact:</p>
    <p>Nacira Bogenschneider</p>
    <p>UST. ID: DE319919006</p>
    <h5>Address:</h5> 
    <p>Astweg 8</p>
    <p>22523 Hamburg</p>
    <p>Phone: 0172 528 70 69</p>
    <p>E-mail: mail@nacira.de</p>
    <p>Responsible for journalistic and editorial contents according to § 55 II RstV:</p>
    <p>Nacira Bogenschneider</p>
    <h5>Pictures and Graphics:</h5>
    <p>Information from the source for used pictures and graphics:</p>
    <p>Nacira Bogenschneider</p>
    </div>
</section>
